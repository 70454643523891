<template>
  <a-modal
    class="op-product-sku-select-dialog"
    :title="$t('order.orderEdit.selectProduct')"
    :visible="visible"
    :centered="true"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-input-search
      v-model.trim="searchFormData.name"
      :placeholder="$t('setting.staff.search')"
      class="op-product-sku-select-dialog__search-input"
      @change="onSearch"
      @search="onSearch"
    />
    <op-table
      row-key="id"
      :columns="tableColumnsForSPU"
      :data-source="spuList"
      :bordered="false"
      :pagination="pagination"
      :scroll="{ y: 300 }"
      :expanded-row-keys.sync="expandedRowKeys"
      :loading="loading"
      @change="handleTableChange"
    >
      <template #picUrl="text">
        <op-image
          :src="text"
          :size="40"
        ></op-image>
      </template>
      <template #customCheckbox="text, spu">
        <a-checkbox
          :indeterminate="getIndeterminateForSPU(spu)"
          :checked="getCheckedForSPU(spu)"
          @change="e => onSelectForSPU(spu, e.target.checked)"
        ></a-checkbox>
      </template>
      <template #expandIcon="{ record, expanded, onExpand }">
        <a-icon
          v-if="record.skuInfoList.length > 0"
          :type="expanded ? 'up' : 'down'"
          @click="e => onExpand(record, e)"
        />
      </template>
      <template #expandedRowRender="spu">
        <a-table
          row-key="skuId"
          :show-header="false"
          :columns="tableColumnsForSKU"
          :data-source="spu.skuInfoList || []"
          :pagination="false"
        >
          <template #customCheckbox="text, sku">
            <a-checkbox
              :checked="getCheckedForSKU(sku, spu)"
              @change="e => onSelectForSKU(sku, e.target.checked, spu)"
            ></a-checkbox>
          </template>
        </a-table>
      </template>
    </op-table>
  </a-modal>
</template>

<script>
import { removeEmptyField } from '@/utils'
import { debounce, cloneDeep } from 'lodash'
import { getTableColumnsForSPU, getTableColumnsForSKU } from './config'
import { getDiscountProductList } from '@/api/discount'
export default {
  name: 'OpProductSkuSelectDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableColumnsForSPU: getTableColumnsForSPU(),
      tableColumnsForSKU: getTableColumnsForSKU(),
      spuList: [],
      selectedSpuList: [],
      searchFormData: {
        name: '',
      },
      expandedRowKeys: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      loading: false,
    }
  },
  computed: {
    expandedRowKeysForSPU() {
      return this.spuList.map(item => item.id)
    },
  },
  created() {
    this.getDiscountProductList()
    this.selectedSpuList = cloneDeep(this.selectedList)
  },
  methods: {
    async getDiscountProductList() {
      try {
        this.loading = true
        const paginationParams = {
          start: this.pagination.pageSize * (this.pagination.current - 1),
          size: this.pagination.pageSize,
        }
        const { data, totalRecords } = await getDiscountProductList({
          ...paginationParams,
          ...removeEmptyField(this.searchFormData),
        })
        const expandedRowKeys = []
        this.spuList = (data || []).map(spu => {
          spu.skuInfoList = spu.skuInfoList || []
          spu.skuSelectedIds = []
          const cachedSpuIndex = this.selectedSpuList.findIndex(
            cachedProduct => cachedProduct.id === spu.id
          )
          if (cachedSpuIndex !== -1) {
            const cachedSpu = this.selectedSpuList[cachedSpuIndex]
            const cachedSelectedSkuIds = (cachedSpu.skuInfoList || []).map(item => item.skuId)
            spu.skuSelectedIds = cachedSelectedSkuIds
            // 将无规格的skuInfoList去除，与列表返回结构一致
            if (spu.skuInfoList.length === 0) {
              spu.skuSelectedIds = []
            }
            this.selectedSpuList.splice(cachedSpuIndex, 1, spu)
          }
          if (spu.skuInfoList.length > 0) {
            expandedRowKeys.push(spu.id)
          }
          return spu
        })
        this.expandedRowKeys = expandedRowKeys
        this.pagination.total = totalRecords || 0
      } finally {
        this.loading = false
      }
    },
    onSearch: debounce(function () {
      this.getDiscountProductList()
    }, 800),
    onSelectForSPU(spu, selected) {
      if (selected) {
        spu.skuSelectedIds = spu.skuInfoList.map(item => item.skuId)
      } else {
        spu.skuSelectedIds = []
      }
      this.updateSpuListAndSelectedSpuList(spu)
    },
    onSelectForSKU(sku, selected, spu) {
      if (selected) {
        spu.skuSelectedIds = [...spu.skuSelectedIds, sku.skuId]
      } else {
        spu.skuSelectedIds = spu.skuSelectedIds.filter(item => item !== sku.skuId)
      }
      this.updateSpuListAndSelectedSpuList(spu)
    },
    updateSpuListAndSelectedSpuList(spu) {
      const skuSelectedIds = spu.skuSelectedIds || []
      const selectedSpuIndex = this.selectedSpuList.findIndex(item => item.id === spu.id)
      if (skuSelectedIds.length > 0 && selectedSpuIndex === -1) {
        this.selectedSpuList.push(spu)
      }
      if (skuSelectedIds.length > 0 && selectedSpuIndex !== -1) {
        this.selectedSpuList.splice(selectedSpuIndex, 1, spu)
      }
      if (skuSelectedIds.length === 0 && selectedSpuIndex !== -1) {
        this.selectedSpuList.splice(selectedSpuIndex, 1)
      }
      if (skuSelectedIds.length === 0 && selectedSpuIndex === -1) {
        this.selectedSpuList.push(spu)
      }
      const spuIndex = this.spuList.findIndex(item => item.id === spu.id)
      this.spuList.splice(spuIndex, 1, spu)
    },
    getIndeterminateForSPU(spu) {
      const skuSelectedIds = spu.skuSelectedIds || []
      const skuInfoList = spu.skuInfoList || []
      return skuSelectedIds.length > 0 && skuSelectedIds.length < skuInfoList.length
    },
    getCheckedForSPU(spu) {
      const skuSelectedIds = spu.skuSelectedIds || []
      const skuInfoList = spu.skuInfoList || []
      if (skuInfoList.length > 0) {
        return skuSelectedIds.length === skuInfoList.length
      } else {
        return !!this.selectedSpuList.find(item => item.id === spu.id)
      }
    },
    getCheckedForSKU(sku, spu) {
      const skuSelectedIds = spu.skuSelectedIds || []
      return skuSelectedIds.includes(sku.skuId)
    },
    handleOk() {
      const newSelectedSpuList = this.selectedSpuList.map(item => {
        const skuInfoList = item.skuSelectedIds.map(skuId =>
          item.skuInfoList.find(sku => sku.skuId === skuId)
        )
        item.skuInfoList = skuInfoList
        // 当skuInfoLit为空时，将spu带的数据放入其中，与业务使用同步
        if (item.skuInfoList.length === 0) {
          item.skuInfoList = [
            {
              skuId: item.skuId,
              stockCount: item.stockCount,
              originalPrice: item.originalPrice,
              salePrice: item.salePrice,
              costPrice: item.costPrice,
              goodsId: item.id,
              specValueNameList: [],
            },
          ]
        }
        delete item.skuSelectedIds
        return item
      })
      this.$emit('update:visible', false)
      this.$emit('ok', newSelectedSpuList)
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    handleTableChange(pagination) {
      this.pagination = { ...this.pagination, ...pagination }
      this.getDiscountProductList()
    },
  },
}
</script>

<style lang="less" scoped>
.op-product-sku-select-dialog {
  background: #fff;
  /deep/ .ant-modal-body {
    height: 350px;
    padding-top: 16px;
    padding-bottom: 0;
    overflow-y: scroll;
  }
  &__search-input {
    margin-bottom: 16px;
  }
  &__content {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>
