import OpLink from './op-link/index.vue'
import OpPageCard from './op-page-card/index.vue'
import OpProductGroupSelectDialog from './op-product-group-select-dialog/index.vue'
import OpProductSelectDialog from './op-product-select-dialog/index.vue'
import OpSearchForm from './op-search-form/index.vue'
import OpTable from './op-table/index.vue'
import OpTinymceEditor from './op-tinymce-editor/index.vue'
import OpTableBatchOperate from './op-table-batch-operate/index.vue'
import OpLoadingButton from './op-loading-button/index.vue'
import OpFormSaveToolbar from './op-form-save-toolbar/index.vue'
import OpSelect from './op-select/index.vue'
import OpSiteLogo from './op-site-logo/index.vue'
import OpInputNumber from './op-input-number/index.vue'
import OpImage from './op-image/index.vue'
import OpUploadImage from './op-upload-image/index.vue'
import OpUploadFile from './op-upload-file/index.vue'
import OpLanguageDropdown from './op-language-dropdown'
import OpLanguageDropdownMenu from './op-language-dropdown-menu'
import OpCheckbox from './op-checkbox'
import OpSwiper from './op-swiper'
import OpActivityBanner from './op-activity-banner'
export default [
  OpActivityBanner,
  OpLink,
  OpPageCard,
  OpProductGroupSelectDialog,
  OpProductSelectDialog,
  OpSearchForm,
  OpTable,
  OpTinymceEditor,
  OpTableBatchOperate,
  OpLoadingButton,
  OpFormSaveToolbar,
  OpSelect,
  OpSiteLogo,
  OpInputNumber,
  OpImage,
  OpUploadImage,
  OpUploadFile,
  OpLanguageDropdown,
  OpLanguageDropdownMenu,
  OpCheckbox,
  OpSwiper,
]
