<template>
  <div
    class="site-logo"
    @click="goHome"
  >
    <img
      v-if="isLight"
      v-compress="{
        src: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/common/logo-light.png',
      }"
      :height="height"
    />
    <img
      v-else
      v-compress="{
        src: 'https://saas-fe-prod.pandova.com/static/pandova-admin/images/common/logo-dark.png',
      }"
      :height="height"
    />
  </div>
</template>

<script>
export default {
  name: 'OpSiteLogo',
  props: {
    isLight: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  methods: {
    goHome() {
      this.$router.push({ path: '/portal/portal-home' })
    },
  },
}
</script>
<style lang="less" scoped>
.site-logo {
  cursor: pointer;
}
</style>
