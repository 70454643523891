<script>
import Select from 'ant-design-vue/es/select'
export default {
  name: 'OpSelect',
  props: Object.assign({}, Select.props, {
    data: {
      type: Function,
      default: () => {},
    },
    // searchKey: {
    //   type: String,
    //   default: 'value',
    // },
    labelKey: {
      type: String,
      default: '',
    },
    valueKey: {
      type: String,
      default: '',
    },
    responseProcessor: {
      type: Function,
      default: res => {
        return {
          value: res.value,
          label: res.label,
        }
      },
    },
    defaultOptions: {
      type: Array,
      default: () => [],
    },
  }),
  data() {
    return {
      localOptions: [],
      requestParams: {
        start: 0,
        size: 200,
      },
      totalRecords: 0,
      searchValue: '',
    }
  },
  computed: {
    // filteredLocalOptions() {
    //   return this.localOptions.filter(item => {
    //     console.log(item[this.labelKey], this.searchValue)
    //     return item[this.labelKey].includes(this.searchValue)
    //   })
    // },
  },
  watch: {
    // defaultOptions: {
    //   handler(options) {
    //     const clonedOptions = options
    //     let selectedOptions = []
    //     if (this.localOptions.length === 0) {
    //       selectedOptions = clonedOptions
    //     } else {
    //       selectedOptions = (clonedOptions || []).filter(option => {
    //         return this.localOptions.find(item => item[this.valueKey] !== option[this.valueKey])
    //       })
    //     }
    //     const newSelectedOptions = selectedOptions.map(item => {
    //       if (this.valueKey && this.labelKey) {
    //         return {
    //           value: item[this.valueKey],
    //           label: item[this.labelKey],
    //           ...item,
    //         }
    //       } else {
    //         return this.responseProcessor(item)
    //       }
    //     })
    //     this.localOptions.unshift(...newSelectedOptions)
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.fetchData()
  },
  methods: {
    handleChange(val) {
      const item = this.localOptions.find(item => item[this.valueKey] === val)
      this.$emit('input', val)
      this.$emit('change', val, item)
    },
    handlePopupScroll(event) {
      // const target = event.target
      // if (target.scrollTop + target.offsetHeight - target.scrollHeight === 0) {
      //   this.fetchData()
      // }
      // this.$emit('popupScroll', event)
    },
    handleSearch(value) {
      this.searchValue = value
      this.$emit('search', value)
    },
    fetchData() {
      this.data(this.requestParams)
        .then(res => {
          this.localOptions = (res.data || []).map(item => {
            if (this.valueKey && this.labelKey) {
              return {
                value: item[this.valueKey],
                label: item[this.labelKey],
                ...item,
              }
            } else {
              return this.responseProcessor(item)
            }
          })
          this.localOptions = data
          // this.filteredLocalOptions = this.localOptions
          // this.totalRecords = res.totalRecords || 0
          // this.requestParams.start = this.requestParams.start + this.requestParams.size
        })
        .catch(() => {})
    },
  },
  render() {
    const props = {
      ...this.$props,
    }
    if (this.localOptions.length > 0) {
      props.options = this.localOptions
    }
    const on = {
      ...this.$listeners,
      change: this.handleChange,
      // popupScroll: this.handlePopupScroll,
      search: this.handleSearch,
    }
    const Select = (
      <a-select
        {...{ props, scopedSlots: { ...this.$scopedSlots } }}
        {...{ on }}
        style="width: 100%"
      >
        {Object.keys(this.$slots).map(name => (
          <template slot={name}>{this.$slots[name]}</template>
        ))}
      </a-select>
    )
    return <div class="op-select"> {Select} </div>
  },
}
</script>

<style lang="less" scoped>
.op-select {
}
</style>
