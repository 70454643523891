<template>
  <div
    :class="{ fullscreen: fullscreen }"
    class="tinymce-container"
    :style="{ width: containerWidth }"
  >
    <textarea
      :id="tinymceId"
      class="tinymce-textarea"
    />
    <div class="editor-custom-btn-container">
      <editor-image-upload
        color="#1890ff"
        :visible="uploadImgVisible"
        :module-name="moduleName"
        class="editor-upload-btn"
        @close="closeImageUpload"
        @success="imageSuccess"
      />
    </div>
  </div>
</template>

<script>
import { importScript } from 'runtime-import'
import EditorImageUpload from './editor-image-upload.vue'
import { plugins, toolbar } from './config'
import { uploadSingleImage } from '@/api/common'
import { generateUUID } from '@/utils'
import { mapState } from 'vuex'
import { LANGUAGE_TYPE } from '@/constants/common'
export default {
  name: 'OpTinymceEditor',
  components: { EditorImageUpload },
  props: {
    id: {
      type: String,
      default: function () {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
      },
    },
    value: {
      type: String,
      default: '',
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    menubar: {
      type: String,
      default: '',
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360,
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto',
    },
    moduleName: {
      type: String,
      default: 'product',
    },
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        ar: 'ar',
      },
      uploadImgVisible: false,
      globalcounter: 1,
    }
  },
  computed: {
    containerWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        return `${width}px`
      }
      return width
    },
    ...mapState(['language']),
  },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() => window.tinymce.get(this.tinymceId).setContent(val || ''))
      }
    },
  },
  async mounted() {
    await importScript('https://saas-fe-prod.pandova.com/static/libs/tinymce@5.10.3/tinymce.min.js')
    this.initTinymce()
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    httpRequest(src) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', src, true)
        xhr.responseType = 'blob'
        xhr.onload = function (e) {
          if (this.status === 200) {
            const myBlob = this.response
            const files = new window.File([myBlob], generateUUID() + '.png', { type: myBlob.type }) // myBlob.type 自定义文件名
            resolve(files)
          } else {
            reject(false)
          }
        }
        xhr.send()
      })
    },
    initTinymce() {
      const languageTypeCodeMap = {
        [LANGUAGE_TYPE.ARABIC]: 'ar',
        [LANGUAGE_TYPE.CHINESE]: 'zh_CN',
      }
      const languageTypeUrlMap = {
        [LANGUAGE_TYPE.ARABIC]:
          'https://saas-fe-prod.pandova.com/static/libs/tinymce@5.10.3/langs/ar.js',
        [LANGUAGE_TYPE.CHINESE]:
          'https://saas-fe-prod.pandova.com/static/libs/tinymce@5.10.3/langs/zh_CN.js',
      }
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        skin_url: 'https://saas-fe-prod.pandova.com/static/libs/tinymce@5.10.3/skins/ui/oxide',
        language: languageTypeCodeMap[this.language],
        language_url: languageTypeUrlMap[this.language],
        directionality: this.language === LANGUAGE_TYPE.ARABIC ? 'rtl' : 'ltr',
        height: this.height,
        body_class: 'panel-body ',
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar,
        plugins: plugins,
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        default_link_target: '_blank',
        link_title: false,
        paste_data_images: true,
        paste_preprocess: async (plugin, args) => {
          if (args.content.includes('<img')) {
            args.content = args.content.replace(
              '<img',
              '<img id="pasted_image_' + parseInt(this.globalcounter) + '"'
            )
            const blobSrc = args.content.split('"')[3]
            const responseFile = await this.httpRequest(blobSrc)
            this.uploadImage(responseFile)
          }
        },
        init_instance_callback: editor => {
          this.hasInit = true
          if (this.value) {
            editor.setContent(this.value)
          }
          editor.on('Change KeyUp SetContent', () => {
            this.hasChange = true
            this.$emit('input', editor.getContent())
          })
        },
        setup: editor => {
          editor.on('FullscreenStateChanged', e => {
            this.fullscreen = e.state
          })
          editor.ui.registry.addButton('customImageButton', {
            icon: 'image',
            onAction: e => {
              this.uploadImgVisible = true
            },
          })
        },
        convert_urls: false,
        branding: false,
      })
    },
    async uploadImage(file) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('moduleName', this.moduleName)
      const { data } = await uploadSingleImage(formData)
      const isIFrame = input => input !== null && input.tagName === 'IFRAME'
      // 考虑页面中有多个富文本的情况
      const pageMultipleIframe = parent.document.getElementsByTagName('iframe')
      const thisIframeID = `${this.tinymceId}_ifr`
      const currentIframe =
        Array.from(pageMultipleIframe).find(item => item.id === thisIframeID) || {}
      /**
       *  将粘贴的img标签里的src值，用图片上传返回的url值 【强制】 替换；
       *  强制替换是因为图片此时已经显示在富文本里了；
       *  start
       */
      if (isIFrame(currentIframe) && currentIframe.contentWindow) {
        currentIframe.contentWindow.document
          .getElementById(`pasted_image_${this.globalcounter}`)
          .setAttribute('src', data)
      }
      this.globalcounter += 1
    },
    closeImageUpload() {
      this.uploadImgVisible = false
    },
    destroyTinymce() {
      if (!window.tinymce) return
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen')
      }
      tinymce.destroy()
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccess(arr) {
      arr.forEach(item =>
        window.tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" src="${item}" >`)
      )
    },
  },
}
</script>

<style lang="less" scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
  /deep/ .mce-fullscreen {
    z-index: 10000;
  }
  /deep/ .mce-stack-layout {
    overflow-x: scroll;
  }
}
.tinymce-textarea {
  z-index: -1;
  visibility: hidden;
}

.editor-custom-btn-container {
  position: absolute;
  top: 4px;
  right: 4px;
}

.fullscreen .editor-custom-btn-container {
  position: fixed;
  z-index: 10000;
}
.editor-upload-btn {
  display: inline-block;
}
</style>
