<script>
import Table from 'ant-design-vue/es/table/Table'
export default {
  name: 'OpTable',
  props: Object.assign({}, Table.props, {
    size: {
      type: String,
      default: 'middle',
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Function,
      default: () => { },
    },
    autoLoad: {
      type: Boolean,
      default: true,
    },
    responseProcessor: {
      type: Function,
      default: res => {
        return {
          data: res.data || [],
          total: res.totalRecords || 0,
          stat: res.stat || {},
        }
      },
    },
  }),
  data() {
    return {
      localLoading: false,
      localDataSource: [],
      localPagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50', '100'],
        showSizeChanger: true,
        showQuickJumper: true,
        total: 0,
        showTotal: total => `${$t('common.totalAmount')} ${total}`,
        size: 'small',
      },
    }
  },
  watch: {
    pagination: {
      handler(val) {
        if (val !== false) {
          this.localPagination = Object.assign({}, this.localPagination, val)
        }
      },
      deep: true,
    },
  },
  created() {
    this.autoLoad && this.fetchData()
  },
  methods: {
    // 加载数据
    fetchData(pagination, filters, sorter) {
      pagination && this.$emit('change', pagination, filters, sorter)
      this.localLoading = true
      this.localPagination = Object.assign({}, this.localPagination, pagination)
      const commonParams = {
        start: this.localPagination.pageSize * (this.localPagination.current - 1),
        size: this.localPagination.pageSize,
      }
      if (sorter && sorter.order) {
        commonParams.sortField = sorter.field
        commonParams.sortType = (sorter.order || '').replace('end', '')
      }
      const result = this.data(commonParams)
      this.localDataSource = []
      if (typeof result === 'object' && typeof result.then === 'function') {
        result
          .then(res => {
            res = this.responseProcessor(res)
            const { data, total, stat } = res
            this.localDataSource = data
            this.localPagination.total = total
            this.stat = stat
          })
          .finally(() => {
            this.localLoading = false
          })
      } else {
        this.localLoading = false
      }
    },
    // 对外暴露方法：重新加载数据方法
    reloadData(isFirstPage = false) {
      if (isFirstPage) {
        this.localPagination.current = 1
      }
      this.fetchData()
    },
  },
  render() {
    const scrollWidth = this.columns.reduce((total, next) => {
      return total + parseInt(next.minWidth || next.width)
    }, 0)
    if (Number.isNaN(scrollWidth)) {
      alert('minWidth或width属性未设置')
      return
    }
    const props = {
      ...this.$props,
      loading: this.localLoading,
      scroll: { x: scrollWidth },
    }
    const on = {
      ...this.$listeners,
      change: this.fetchData,
    }

    const { dataSource, pagination } = this.$props
    if (pagination !== false) {
      props.pagination = this.localPagination
    }

    if (dataSource.length === 0) {
      props.dataSource = this.localDataSource
    }

    const table = (
      <a-table {...{ props, scopedSlots: { ...this.$scopedSlots } }} {...{ on }}>
        {Object.keys(this.$slots).map(name => (
          <template slot={name}>{this.$slots[name]}</template>
        ))}
      </a-table>
    )

    return <div class="op-table">{table}</div>
  },
}
</script>

<style lang="less" scoped>
.op-table {
}
</style>
