import OpDateRange from './op-date-range.vue'
import OpInput from './op-input.vue'
import OpInputRange from './op-input-range.vue'
import OpRadioSelect from './op-radio-select.vue'
import OpCheckboxSelect from './op-checkbox-select.vue'

export default { OpDateRange, OpInput, OpInputRange, OpRadioSelect, OpCheckboxSelect }

export const COMPONENT_MAP = {
  'op-date-range': OpDateRange,
  'op-input': OpInput,
  'op-input-range': OpInputRange,
  'op-radio-select': OpRadioSelect,
  'op-checkbox-select': OpCheckboxSelect,
  OpDateRange,
  OpInput,
  OpInputRange,
  OpRadioSelect,
  OpCheckboxSelect,
}
