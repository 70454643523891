<template>
  <div class="op-form-save-toolbar">
    <a-row
      v-if="isScreenXs && !isPandovaApp"
      type="flex"
      justify="end"
      align="middle"
      class="op-form-save-toolbar__top-row"
    >
      <a-col class="account-layout__content">
        <a-space>
          <a-button
            class="op-form-save-toolbar__cancel-button"
            @click="cancel"
          >{{ comCancelText }}</a-button>
          <op-loading-button
            type="primary"
            @click="save"
          >{{ comConfirmText }}</op-loading-button>
        </a-space>
      </a-col>
    </a-row>
    <a-row
      type="flex"
      justify="end"
      align="middle"
      class="op-form-save-toolbar__bottom-row"
    >
      <a-col>
        <a-space>
          <a-button
            class="op-form-save-toolbar__cancel-button"
            @click="cancel"
          >{{ comCancelText }}</a-button>
          <op-loading-button
            type="primary"
            @click="save"
          >{{ comConfirmText }}</op-loading-button>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isPandovaApp } from '@/utils'
export default {
  name: 'OpFormSaveToolbar',
  props: {
    confirmText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
  },
  data() {
    return { isPandovaApp: isPandovaApp() }
  },
  computed: {
    ...mapState(['isScreenXs']),
    comConfirmText() {
      return this.confirmText || $t('common.saveText')
    },
    comCancelText() {
      return this.cancelText || $t('common.cancelText')
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save(done) {
      this.$emit('save', done)
    },
  },
}
</script>

<style lang="less" scoped>
.op-form-save-toolbar {
  &__top-row {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    height: 64px;
    padding-right: 20px;
    line-height: 64px;
    background-color: rgb(67, 67, 67);
  }
  &__bottom-row {
    height: 64px;
    padding-right: 20px;
    line-height: 64px;
  }
  &__cancel-button {
    margin-right: 16px;
  }
}
</style>
