import Vue from 'vue'

import {
  ConfigProvider,
  Input,
  InputNumber,
  Button,
  Switch,
  Radio,
  Checkbox,
  Select,
  Card,
  Form,
  Row,
  Col,
  Modal,
  Table,
  Tabs,
  Icon,
  Badge,
  Popover,
  Dropdown,
  List,
  Avatar,
  Breadcrumb,
  Spin,
  Menu,
  Drawer,
  Tooltip,
  Alert,
  Tag,
  Divider,
  DatePicker,
  TimePicker,
  Upload,
  Progress,
  Skeleton,
  Popconfirm,
  PageHeader,
  Result,
  Descriptions,
  message,
  notification,
  Empty,
  Statistic,
  FormModel,
  Space,
  Slider,
  Collapse,
  Rate,
  Layout,
  TreeSelect,
  Timeline,
} from 'ant-design-vue'

Vue.use(ConfigProvider)
  .use(Alert)
  .use(Divider)
  .use(Upload)
  .use(Descriptions)
  .use(Input)
  .use(InputNumber)
  .use(Button)
  .use(Switch)
  .use(Radio)
  .use(Checkbox)
  .use(Select)
  .use(Card)
  .use(Form)
  .use(Row)
  .use(Col)
  .use(Modal)
  .use(Table)
  .use(Tabs)
  .use(Icon)
  .use(Badge)
  .use(Popover)
  .use(Dropdown)
  .use(List)
  .use(Avatar)
  .use(Breadcrumb)
  .use(Spin)
  .use(Menu)
  .use(Drawer)
  .use(Tooltip)
  .use(Tag)
  .use(DatePicker)
  .use(TimePicker)
  .use(Progress)
  .use(Skeleton)
  .use(Popconfirm)
  .use(PageHeader)
  .use(Result)
  .use(Statistic)
  .use(Empty)
  .use(FormModel)
  .use(Space)
  .use(Slider)
  .use(Collapse)
  .use(Rate)
  .use(Layout)
  .use(TreeSelect)
  .use(Timeline)

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
