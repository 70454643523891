<template>
  <div>
    <a-modal
      title="upload"
      :visible="visible"
      :destroy-on-close="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <op-upload-image
        :img-list="fileList"
        :module-name="moduleName"
        :multiple="true"
        :max-count="9"
      ></op-upload-image>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'EditorImageUpload',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    moduleName: {
      type: String,
      default: 'product',
    },
  },
  data() {
    return {
      listObj: {},
      fileList: [],
    }
  },
  methods: {
    getImg(val) {
      this.fileList = val
    },
    handleSubmit() {
      this.$emit('success', this.fileList)
      this.fileList = []
      this.$emit('close')
    },
    handleCancel() {
      this.$emit('close')
    },
  },
}
</script>
