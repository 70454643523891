<template>
  <!-- 查询商品列表 -->
  <a-modal
    class="op-product-group-select-dialog"
    :title="$t('discount.discountList.selectGoods')"
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="op-product-group-select-dialog__content">
      <a-input-search
        v-model.trim="searchFormData.name"
        :placeholder="$t('setting.staff.search')"
        class="op-product-group-select-dialog__search-input"
        @change="onSearch"
        @search="onSearch"
      />
      <op-table
        ref="table"
        :row-selection="rowSelection"
        :columns="columns"
        :bordered="false"
        :data="getShopGoodsList"
        row-key="id"
      ></op-table>
    </div>
  </a-modal>
</template>

<script>
import { removeEmptyField } from '@/utils'
import { getShopGoodsList } from '@/api/product'
import OpTable from '@/components/op-table/index.vue'
import { tableColumn } from './config'
import { debounce } from 'lodash'

export default {
  name: 'OpCollectionSelectDialog',
  components: {
    OpTable,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'radio',
    },
    selectedList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedRows: [],
      columns: tableColumn(),
      searchFormData: {
        name: '',
      },
    }
  },
  computed: {
    rowSelection() {
      return {
        columnTitle: ' ',
        type: this.type,
        selectedRowKeys: this.selectedRows.map(item => item.id),
        onSelect: this.onSelect,
      }
    },
  },
  watch: {
    visible: {
      handler: function (val) {
        if (val) {
          this.searchFormData.name = ''
          this.reloadData()
        }
      },
      deep: true
    }
  },
  created() {
    this.selectedRows = this.selectedList
  },
  methods: {
    getShopGoodsList(commonParams) {
      return getShopGoodsList({ ...commonParams, ...removeEmptyField(this.searchFormData) })
    },
    reloadData(isFirstPage = true) {
      this.$refs.table && this.$refs.table.reloadData(isFirstPage)
    },
    onSelect(record, selected) {
      if (selected) {
        this.selectedRows = [record]
      } else {
        this.handleSelectionClearItem(record)
      }
    },
    handleSelectionClearItem(data) {
      const keys = this.selectedRows.map(item => item.id)
      this.selectedRows.splice(keys.indexOf(data.id), 1)
    },
    onSearch: debounce(function () {
      this.reloadData(true)
    }, 800),
    handleOk() {
      this.$emit('update:visible', false)
      if (this.type === 'checkbox') {
        this.$emit('ok', this.selectedRows)
      } else {
        this.$emit('ok', this.selectedRows.length === 0 ? '' : this.selectedRows[0])
      }
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
  },
}
</script>
<style lang="less" scoped>
.op-product-group-select-dialog {
  /deep/ .ant-modal-body {
    padding-top: 16px;
  }
  &__search-input {
    margin-bottom: 16px;
  }
  &__content {
    max-height: 400px;
    overflow-y: scroll;
  }
}
</style>
