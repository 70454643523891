<template>
  <div class="op-radio-select">
    <a-dropdown
      v-if="!inSearchFormDrawer"
      :trigger="['click']"
    >
      <div
        slot="overlay"
        class="op-radio-select__overlay"
      >
        <op-radio-group
          :item="item"
          :value="value"
          @change="handleChange"
        ></op-radio-group>
      </div>
      <a-button
        trigger="click"
        class="op-radio-select__button"
      >
        {{ item.title }}
        <a-icon type="down" />
      </a-button>
    </a-dropdown>
    <op-radio-group
      v-if="inSearchFormDrawer"
      :item="item"
      :value="value"
      @change="handleChange"
    ></op-radio-group>
  </div>
</template>

<script>
import OpRadioGroup from './op-radio-group.vue'
export default {
  name: 'OpRadioSelect',
  components: {
    OpRadioGroup,
  },
  props: {
    inSearchFormDrawer: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Boolean],
      default: undefined,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleChange(field, value) {
      this.$emit('change', field, value)
    },
  },
}
</script>

<style lang="less" scoped>
.op-radio-select {
  &__overlay {
    min-width: 160px;
    padding: 5px 10px;
    background: #fff;
    border: 1px solid #ebedf0;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
