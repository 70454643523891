<template>
  <div
    :class="componentId"
    class="op-swiper"
  >
    <swiper
      ref="opSwiper"
      :options="options"
    >
      <slot></slot>
      <div
        slot="pagination"
        class="swiper-pagination"
      ></div>
    </swiper>

    <div
      v-if="changePageButton"
      slot="button-prev"
      class="swiper-button-prev"
    ></div>
    <div
      v-if="changePageButton"
      slot="button-next"
      class="swiper-button-next"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'OpSwiper',
  components: {},
  props: {
    swiperOption: {
      type: Object,
      default: () => ({}),
    },
    componentId: {
      type: String,
      default: 'component-default',
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    changePageButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      Swiper: null,
      defaultSwiperOption: {
        speed: 200,
        paginationClickable: true,
        pagination: {
          el: '.' + this.componentId + ' .swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.' + this.componentId + ' .swiper-button-next',
          prevEl: '.' + this.componentId + ' .swiper-button-prev',
        },
        initialSlide: 0,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 5000,
        },
      },
    }
  },

  computed: {
    options() {
      return Object.assign(this.defaultSwiperOption, this.swiperOption)
    },
  },

  watch: {
    activeIndex(val) {
      this.slideTo(val)
    },
  },

  methods: {
    slideTo(index) {
      this.$refs?.opSwiper?.swiper.slideTo(index)
    },
  },
}
</script>
<style lang="less" scoped>
.op-swiper {
  position: relative;
  width: 100%;
  .swiper-pagination {
    bottom: 8px;
    bottom: 0 !important;
    left: 50% !important;
    width: auto !important;
  }

  /deep/ .swiper-pagination-bullet-active {
    background: #999 !important;
  }
  /deep/ .swiper-pagination-bullet {
    margin: 0 5px;
    background: #afa5a5;
  }
}

/* rtl:begin:ignore */
[dir='rtl'] .op-swiper {
  .swiper-button-next {
    transform: rotate(180deg) scale(0.5) !important;
  }
  .swiper-button-prev {
    transform: rotate(180deg) scale(0.5) !important;
  }
}

/* rtl:end:ignore */
</style>
