<template>
  <img
    v-compress="{
      src:
        src ||
        'https://saas-fe-prod.pandova.com/static/pandova-admin/images/product/default-product.png',
    }"
    :alt="alt"
    :style="imageStyle"
    class="op-image"
    onerror="this.src='https://saas-fe-prod.pandova.com/static/pandova-admin/images/product/default-product.png'"
  />
</template>
<script>
export default {
  name: 'OpImage',
  props: {
    size: {
      type: Number,
      default: 60,
    },
    src: {
      type: String,
      default: '',
    },
    shape: {
      type: String,
      default: 'square',
    },
    alt: {
      type: String,
      default: '',
    },
    objectFit: {
      type: String,
      default: 'contain',
    },
    needPreviewMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: this.shape === 'circle' ? '50%' : 'none',
        objectFit: this.objectFit,
        cursor: this.needPreviewMode ? 'pointer' : 'auto',
      }
    },
  },
}
</script>

<style lang="less" scoped></style>
