<template>
  <div class="op-checkbox-group">
    <a-checkbox-group
      :value="value"
      :options="item.options || []"
      @change="handleChange"
    ></a-checkbox-group>
    <a-button
      type="link"
      block
      :disabled="clearButtonDisabled"
      @click="handleClear"
    >{{ $t('common.clearText') }}</a-button>
  </div>
</template>

<script>
export default {
  name: 'OpCheckboxGroup',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    clearButtonDisabled() {
      return this.value.length === 0
    },
  },
  methods: {
    handleChange(checkedValues) {
      this.$emit('change', this.item.field, checkedValues)
    },
    handleClear() {
      this.$emit('change', this.item.field, [])
    },
  },
}
</script>

<style lang="less" scoped>
.op-checkbox-group {
  display: flex;
  flex-direction: column;
  /deep/ .ant-checkbox-group {
    width: 100%;
    .ant-checkbox-wrapper {
      display: block;
      height: 30px;
      line-height: 30px;
    }
  }
  /deep/ .ant-btn-link {
    padding: 0;
    text-align: left;
  }
}
</style>
