<template>
  <a-menu
    slot="overlay"
    :selected-keys="[currentSelectedMenu]"
    class="op-language-dropdown__language-menu"
  >
    <a-menu-item
      v-for="item in LANGUAGE_COUNTRY_TYPE_TEXT_MAP_LIST"
      :key="item.value"
      class="op-language-dropdown__language-menu__item"
      @click="handleChangeLanguage(item.value)"
    >
      <img
        class="op-language-dropdown__language-menu__item__flag"
        :src="LANGUAGE_COUNTRY_FLAG[item.value]"
      />
      {{ item.label }}
    </a-menu-item>
  </a-menu>
</template>
<script>
import {
  LANGUAGE_COUNTRY_TYPE_TEXT_MAP_LIST,
  languageMap,
  countryMap,
  LANGUAGE_COUNTRY_FLAG,
} from '@/constants/common'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'OpLanguageDropdownMenu',
  data() {
    return {
      LANGUAGE_COUNTRY_TYPE_TEXT_MAP_LIST,
      LANGUAGE_COUNTRY_FLAG,
    }
  },
  computed: {
    ...mapState(['language', 'countryCode']),
    currentSelectedMenu() {
      return `${this.countryCode}-${this.language}`
    },
  },
  methods: {
    ...mapMutations(['setLanguage']),
    handleChangeLanguage(languageCountry) {
      this.$emit('change', {
        countryCode: countryMap[languageCountry],
        languageCode: languageMap[languageCountry],
      })
    },
  },
}
</script>
<style lang="less" scoped>
.op-language-dropdown {
  &__language-menu {
    &__item {
      &__flag {
        height: 10px;
        margin: 0 2px;
      }
    }
  }
}
</style>
