<template>
  <div class="op-input">
    <a-input
      :value="value"
      :placeholder="inSearchFormDrawer ? '' : item.title"
      :allow-clear="true"
      v-bind="item.props || {}"
      @change="change"
    >
      <a-icon
        v-if="!inSearchFormDrawer"
        slot="prefix"
        type="search"
      />
    </a-input>
  </div>
</template>

<script>
import { debounce } from 'lodash'
export default {
  name: 'OpInput',
  props: {
    inSearchFormDrawer: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  methods: {
    change(e) {
      if (e.type === 'input') {
        this.handleInput(e.target.value.trim())
        return
      }
      // clear场景去除debounce，避免debounce带来的值不更新问题
      this.$emit('change', this.item.field, '')
    },
    handleInput: debounce(function (value) {
      this.$emit('change', this.item.field, value)
    }, 800),
  },
}
</script>

<style lang="less" scoped></style>
