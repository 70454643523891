<script>
/**
 * 带loading效果的按钮
 */
export default {
  name: 'OpLoadingButton',
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    _click() {
      if (this.loading) {
        return
      }
      this.loading = true
      this.$emit('click', this.done.bind(this))
    },
    done() {
      this.loading = false
    },
  },
  render(create) {
    return create(
      'a-button',
      {
        props: {
          ...this.props,
        },
        attrs: {
          ...this.$attrs,
          loading: this.loading,
        },
        on: {
          ...this.$listeners,
          click: this._click,
        },
      },
      /**
       * @slot 按钮内容
       */
      this.$slots.default
    )
  },
}
</script>
