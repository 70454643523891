<template>
  <div class="op-upload-file">
    <a-upload
      :file-list="currentFileList"
      :remove="handleRemove"
      :before-upload="beforeUploadFile"
      :custom-request="uploadFile"
    >
      <div class="op-upload-file__upload">
        <a-row
          type="flex"
          justify="center"
          align="middle"
          :gutter="[16, 0]"
        >
          <a-col>
            <a-icon
              :type="loading ? 'loading' : 'cloud-upload'"
              class="op-upload-file__upload-icon"
            />
          </a-col>
          <a-col>{{ $t('setting.payment.chooseFile') }}</a-col>
        </a-row>
      </div>
    </a-upload>
  </div>
</template>
<script>
import { Upload } from 'ant-design-vue'
import { uploadFile } from '@/api/setting'
import { isEmptyValue } from '@/utils/validator'
const IMAGE_TYPE_ACCEPT_LIST = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']
export default {
  name: 'OpUploadFile',
  components: {},
  props: Object.assign({}, Upload.props, {
    moduleName: {
      type: String,
      default: 'account/merchant',
    },
    fileList: {
      type: Array,
      default: () => [],
    },
  }),
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    currentFileList() {
      return this.fileList.map(item => {
        return {
          uid: item,
          name: item,
          status: 'done',
          url: item,
        }
      })
    },
    fileUrl() {
      return isEmptyValue(this.fileList[0])
    },
  },
  methods: {
    handleRemove() {
      this.fileList.splice(0, 1)
      this.$emit('remove', this.fileList)
    },
    beforeUploadFile(file) {
      this.loading = true
      if (!IMAGE_TYPE_ACCEPT_LIST.includes(file.type)) {
        this.$message.error($t('setting.payment.businessLogoLimitDescription'))
        this.loading = false
        return false
      }
    },
    async uploadFile(file) {
      try {
        const formData = new FormData()
        formData.append('file', file.file)
        formData.append('moduleName', this.moduleName)
        const { data } = await uploadFile(formData)
        this.multiple ? this.fileList.push(data) : this.fileList.splice(0, 1, data)
        this.$emit('success', data)
      } catch (error) {
        this.fileList.splice(0, 1)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="less" scoped>
.op-upload-file {
  width: 100%;
  cursor: pointer;
  &__upload {
    padding: 10px 20px;
    text-align: center;
    background-color: #e5f8f1;
    border-radius: 4px;
  }
  &__upload-icon {
    font-size: 40px;
    color: #1dcf9f;
  }
  /deep/ .ant-upload-select-text {
    width: 100% !important;
  }
  /deep/ .ant-upload-list-item {
    height: 30px;
    line-height: 32px;
  }
  /deep/ .ant-upload-list-item-card-actions .anticon-delete {
    display: inline-block;
    width: 30px;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    background-color: #ebefed;
  }
  /deep/ .anticon-delete svg {
    position: relative;
    top: 3px;
  }
  /deep/ .anticon {
    padding-right: 0;
  }
  /deep/ .anticon-paper-clip {
    display: inline-block;
    margin-right: 8px;
    font-size: 16px;
    line-height: 26px;
  }
  /deep/ .ant-upload-list-item-name {
    width: 95%;
  }
}
</style>
