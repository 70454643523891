<template>
  <div class="op-date-range">
    <a-space direction="vertical">
      <a-date-picker
        :value="startValue"
        :placeholder="$t('comment.startDate')"
        :show-today="false"
        :disabled-date="startDisabledDate"
        input-read-only
        allow-clear
        @change="changeStartValue"
      />
      <a-date-picker
        :value="endValue"
        :placeholder="$t('comment.endDate')"
        :show-today="false"
        :disabled-date="endTimeDisabledDate"
        input-read-only
        allow-clear
        @change="changeEndValue"
      />
    </a-space>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'OpDateRange',
  props: {
    inSearchFormDrawer: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    startValue() {
      return this.value[0]
    },
    endValue() {
      return this.value[1]
    },
  },
  methods: {
    startDisabledDate(current) {
      return current && current > moment(this.value[1]).subtract(1, 'days').endOf('day')
    },
    endTimeDisabledDate(current) {
      return current && current < +moment(this.value[0]).subtract(1, 'days').endOf('day')
    },
    changeStartValue(e) {
      this.$emit('change', this.item.field, [e, this.value[1]])
    },
    changeEndValue(e) {
      this.$emit('change', this.item.field, [this.value[0], e])
    },
  },
}
</script>

<style lang="less" scoped>
.op-date-range {
  /deep/ .ant-space-vertical,
  /deep/ .ant-calendar-picker {
    width: 100% !important;
  }
}
</style>
