<template>
  <div class="op-checkbox-select">
    <a-dropdown
      v-if="!inSearchFormDrawer"
      v-model="visible"
      :trigger="['click']"
    >
      <div
        slot="overlay"
        class="op-checkbox-select__overlay"
      >
        <op-checkbox-group
          :item="item"
          :value="value"
          @change="handleChange"
        ></op-checkbox-group>
      </div>
      <a-button
        trigger="click"
        class="op-checkbox-select__button"
      >
        {{ item.title }}
        <a-icon type="down" />
      </a-button>
    </a-dropdown>
    <op-checkbox-group
      v-if="inSearchFormDrawer"
      :item="item"
      :value="value"
      @change="handleChange"
    ></op-checkbox-group>
  </div>
</template>

<script>
import OpCheckboxGroup from './op-checkbox-group.vue'
export default {
  name: 'OpCheckboxSelect',
  components: {
    OpCheckboxGroup,
  },
  props: {
    inSearchFormDrawer: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    handleChange(field, value) {
      this.$emit('change', field, value)
    },
  },
}
</script>

<style lang="less" scoped>
.op-checkbox-select {
  &__overlay {
    min-width: 160px;
    padding: 5px 10px;
    background: #fff;
    border: 1px solid #ebedf0;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
