<template>
  <div>
    <a-dropdown class="op-language-dropdown">
      <div
        class="op-language-dropdown__language"
        @click="e => e.preventDefault()"
      >
        <span class="op-language-dropdown__language__label">
          <img
            class="op-language-dropdown__language__label__flag"
            :src="languageCountryFlag"
          />
          {{ languageCountryLabel }}
        </span>
      </div>
      <op-language-dropdown-menu
        slot="overlay"
        @change="handleChangeLanguage"
      />
    </a-dropdown>
  </div>
</template>
<script>
import { LANGUAGE_COUNTRY_TYPE_TEXT_MAP, LANGUAGE_COUNTRY_FLAG } from '@/constants/common'
import { mapState, mapMutations } from 'vuex'
import OpLanguageDropdownMenu from '../op-language-dropdown-menu'

export default {
  name: 'OpLanguageDropdown',
  components: {
    OpLanguageDropdownMenu,
  },
  data() {
    return {
      LANGUAGE_COUNTRY_FLAG,
    }
  },
  computed: {
    ...mapState(['language', 'countryCode']),
    languageCountryLabel() {
      return LANGUAGE_COUNTRY_TYPE_TEXT_MAP[`${this.countryCode}-${this.language}`]
    },
    languageCountryFlag() {
      return LANGUAGE_COUNTRY_FLAG[`${this.countryCode}-${this.language}`]
    },
  },
  methods: {
    ...mapMutations(['setLanguage']),
    handleChangeLanguage(languageCountry) {
      const { countryCode, languageCode } = languageCountry
      this.$store.commit('setLanguage', languageCode)
      this.$store.commit('setCountryCode', countryCode)
      window.location.reload()
    },
  },
}
</script>
<style lang="less" scoped>
.op-language-dropdown {
  &__language {
    display: flex;
    align-items: center;
    width: auto;
    cursor: pointer;
    &__label {
      margin: 0 10px;
      &__flag {
        height: 10px;
      }
    }
  }
  &__language-menu {
    &__flag {
      height: 12px;
    }
  }
}
</style>
