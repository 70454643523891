<template>
  <div class="op-debug">
    <a-button
      v-if="debugIsShow"
      type="primary"
      @click="debugOpen"
    >debug</a-button>
    <a-modal
      title="debug"
      :visible.sync="debugVisible"
      @cancel="handleCancel"
    >
      <a-form-model>
        <a-form-item label="国家切换">
          <a-select
            v-model="countryCode"
            :options="countryOptions"
            @change="countryChange"
          ></a-select>
        </a-form-item>
      </a-form-model>
      <template #footer>
        <a-button
          type="primary"
          @click="debugClear"
        >删除debug</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { localStorage, setLanguageAndCountry, getUrlParam } from '@/utils'

export default {
  name: 'OpDebug',
  data() {
    return {
      debugIsShow: localStorage.get('debug'),
      debugVisible: false,
      countryCode: localStorage.get('countryCode'),
      countryOptions: [
        { label: '尼日利亚', ip: '105.127.165.36', value: 'NG' },
        { label: '埃及', ip: '105.194.0.52', value: 'EG' },
        { label: '其他国家', ip: '5.8.251.02', value: 'other' },
      ],
    }
  },
  created() {
    const debug = getUrlParam(location.href, 'debug')
    if (debug) {
      localStorage.set('debug', true)
      this.debugIsShow = true
    }
  },
  methods: {
    debugOpen() {
      this.debugVisible = true
    },
    async countryChange(value, option) {
      localStorage.remove('countryCode')
      localStorage.remove('language')
      await setLanguageAndCountry(option.data.props.ip)
      window.location.reload()
    },
    debugClear() {
      localStorage.remove('debug')
      window.location.reload()
    },
    handleCancel() {
      this.debugVisible = false
    },
  },
}
</script>
<style lang="less" scoped>
.op-debug {
  position: absolute;
  bottom: 0;
  z-index: 12;
}
</style>
