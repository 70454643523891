<template>
  <a-modal
    :title="title"
    :visible="visible"
    @cancel="handleClose"
  >
    <div class="description">{{ description }}</div>
    <a-row
      type="flex"
      :gutter="[8, 0]"
    >
      <a-col
        :xs="16"
        :sm="16"
        :md="18"
        :lg="18"
        :xl="18"
        :xxl="18"
      >
        <a-input
          v-model="linkUrl"
          allow-clear
          disabled
        />
      </a-col>
      <a-col
        :xs="8"
        :sm="8"
        :md="6"
        :lg="6"
        :xl="6"
        :xxl="6"
      >
        <a-button
          class="copy_button"
          type="primary"
          @click="copyClick"
        >{{ $t('portal.referral.copyLink') }}</a-button>
      </a-col>
    </a-row>
    <template v-slot:footer>
      <a-button @click="handleClose">{{ $t('product.productGroupList.close') }}</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'OpCopyLinkDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    linkUrl: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    successTip: {
      type: String,
      default: $t('order.orderDetail.paymentLinkCopedToast'),
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    handleClose(e) {
      this.$emit('update:visible', false)
    },
    async copyClick() {
      await this.$copyText(this.linkUrl).then(res => {
        this.$message.success(this.successTip)
        this.$emit('ok', res)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.description {
  margin-bottom: 24px;
  color: #999;
}
.footer-ok {
  padding-top: 12px;
  margin-top: 24px;
  text-align: right;
  border-top: 1px solid #dedede;
}
.copy_button {
  width: 100%;
}
</style>
