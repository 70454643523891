<script>
import InputNumber from 'ant-design-vue/es/input-number'
export default {
  name: 'OpInputNumber',
  props: Object.assign({}, InputNumber.props, {
    type: {
      type: String,
      default: 'money',
    },
    precision: {
      type: Number,
      default: 0,
    },
  }),

  data() {
    return {}
  },
  methods: {
    handleChange(val) {
      this.$emit('input', val)
      this.$emit('change', val)
    },
  },
  render() {
    let props = {
      ...this.$props,
    }
    const on = {
      ...this.$listeners,
      change: this.handleChange,
    }
    if (this.type === 'money') {
      props = {
        ...props,
        min: props.min ? props.min : 0,
        max: props.max ? props.max : 100000000,
        parser: value => value.toString().replace('-', ''),
        formatter: value => value.toString().replace(/[^\d.]/g, ''),
        precision: 2,
      }
    }
    if (this.type === 'float') {
      props = {
        ...props,
        min: props.min ? props.min : 0,
        max: props.max ? props.max : 100000000,
        parser: value => value.toString().replace('-', ''),
        precision: props.precision ? props.precision : 2,
      }
    }
    if (this.type === 'percentage') {
      props = {
        ...props,
        min: props.min ? props.min : 0,
        max: props.max ? props.max : 100,
        formatter: value => `${value}`,
        parser: value => value.toString().replace('-', ''),
      }
    }
    if (this.type === 'number') {
      props = {
        ...props,
        min: props.min ? props.min : 0,
        max: props.max ? props.max : 10000,
        formatter: value => `${value}`,
        parser: value => value.toString().replace('.', '').replace('-', ''),
      }
    }
    if (this.type === 'negativeInt') {
      props = {
        ...props,
        formatter: value => `${value}`,
        min: props.min ? props.min : 0,
        max: props.max ? props.max : 10000,
        parser: value => value.toString().replace('.', ''),
      }
    }
    const inputNumber = <a-input-number {...{ props }} {...{ on }}></a-input-number>
    let template
    const addBeforeMoney = (
      <span class="op-input-number__money op-input-number__money--before">
        {this.$store.state.shopInfo.currency}
      </span>
    )
    const addAfterPercentage = <span class="op-input-number__percentage--after">%</span>
    if (this.type === 'money') {
      template = addBeforeMoney
    }
    if (this.type === 'percentage') {
      template = addAfterPercentage
    }
    return (
      <div
        class={{
          'op-input-number': true,
          'op-input-number__money': this.type === 'money',
          'op-input-number__percentage': this.type === 'percentage',
        }}
      >
        {template}
        {inputNumber}
      </div>
    )
  },
}
</script>

<style lang="less" scoped>
.op-input-number {
  position: relative;
  &__money {
    & .ant-input-number {
      width: 100%;
      padding-left: 50px;
    }
    &--before {
      position: absolute;
      top: 14%;
      left: 2px;
      z-index: 1;
      display: inline-block;
      min-width: 50px;
      height: 72%;
      line-height: 32px;
      text-align: center;
      background-color: #fafafa;
      border-right: 1px solid #d9d9d9;
    }
  }
  &__percentage {
    & .ant-input-number {
      width: 100%;
      padding-right: 40px;
    }
    &--after {
      position: absolute;
      top: 0;
      right: 24px;
      z-index: 8;
    }
  }
}
</style>
