<template>
  <div class="op-radio-group">
    <a-radio-group
      :value="value"
      @change="handleChange"
    >
      <a-radio
        v-for="option in item.options || []"
        :key="option.value"
        :value="option.value"
      >{{ option.label }}</a-radio>
    </a-radio-group>
    <a-button
      type="link"
      block
      :disabled="clearButtonDisabled"
      @click="handleClear"
    >{{ $t('common.clearText') }}</a-button>
  </div>
</template>

<script>
export default {
  name: 'OpRadioGroup',
  props: {
    value: {
      type: [String, Number, Boolean],
      default: undefined,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    clearButtonDisabled() {
      return !this.item.options.some(item => item.value === this.value)
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('change', this.item.field, e.target.value)
    },
    handleClear() {
      this.$emit('change', this.item.field, undefined)
    },
  },
}
</script>

<style lang="less" scoped>
.op-radio-group {
  display: flex;
  flex-direction: column;
  /deep/ .ant-radio-group {
    width: 100%;
    .ant-radio-wrapper {
      display: block;
      height: 30px;
      line-height: 30px;
    }
  }

  /deep/ .ant-btn-link {
    padding: 0;
    text-align: left;
  }
}
</style>
