<template>
  <div class="op-upload-image">
    <a-upload
      list-type="picture-card"
      :file-list="currentFileList"
      :show-upload-list="multiple"
      :before-upload="beforeUploadFile"
      :custom-request="uploadImage"
      v-bind="$props"
      :remove="handleRemove"
      :max-count="maxCount"
      v-on="$listeners"
      @preview="handlePreview"
    >
      <div v-if="!hasMaxCount">
        <div
          v-if="imageUrl && !multiple && !loading"
          class="op-upload-image__image"
        >
          <img
            v-compress="{ src: imageUrl }"
            class="op-upload-image__image__item"
          />
          <a-icon
            v-if="imageUrl"
            type="close-circle"
            class="op-upload-image__image__close-icon"
            @click.stop="removeSingleImg"
          />
        </div>
        <div v-else>
          <a-icon
            :type="loading ? 'loading' : 'plus'"
            class="op-upload-image__icon"
          />
          <div class="op-upload-image__text">{{ $t('common.uploadText') }}</div>
        </div>
      </div>
    </a-upload>
    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="previewCancel"
    >
      <div class="op-upload-image__preview">
        <img
          v-if="previewImage"
          v-compress="{ src: previewImage }"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
import { Upload } from 'ant-design-vue'
import { uploadSingleImage } from '@/api/common'
import { generateUUID } from '@/utils'

const IMAGE_TYPE_ACCEPT_LIST = ['image/jpeg', 'image/jpg', 'image/png']
const IMAGE_SIZE_LIMIT = 10 * 1024 * 1024

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'OpUploadImage',
  components: {},
  props: Object.assign({}, Upload.props, {
    moduleName: {
      type: String,
      default: '',
    },
    imgList: {
      type: Array,
      default: () => [],
    },
    maxCount: {
      type: Number,
      default: 9,
    },
    maxSize: {
      type: Number,
      default: IMAGE_SIZE_LIMIT,
    },
    overrunMessage: {
      type: String,
      default: $t('common.uploadLimitSize'),
    },
  }),
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      loading: false,
    }
  },
  computed: {
    hasMaxCount() {
      return this.currentFileList.length >= this.maxCount
    },
    currentFileList() {
      return this.imgList.map(item => {
        const uid = generateUUID()
        return {
          uid: item,
          name: uid,
          status: 'done',
          url: item,
        }
      })
    },
    imageUrl() {
      return !this.multiple && this.imgList[0]
    },
  },
  methods: {
    removeSingleImg() {
      const file = this.imgList[0]
      this.imgList.splice(0, 1)
      this.$emit('remove', file, this.imgList)
    },
    previewCancel() {
      this.previewImage = ''
      this.previewVisible = false
    },
    // 预览图片
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    // 删除图片
    handleRemove(file) {
      const index = this.currentFileList.indexOf(file)
      this.imgList.splice(index, 1)
      this.$emit('remove', file, this.imgList)
    },
    // 上传图片支持 jpeg，jpg，png格式。且不大于10mb
    beforeUploadFile(file) {
      this.loading = true
      if (!IMAGE_TYPE_ACCEPT_LIST.includes(file.type)) {
        this.$message.error($t('common.uploadLimitFormat'))
        this.loading = false
        return false
      }
      if (file.size > this.maxSize) {
        this.$message.error(this.overrunMessage)
        this.loading = false
        return false
      }
    },
    async uploadImage(file) {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('file', file.file)
        formData.append('moduleName', this.moduleName)
        const { data } = await uploadSingleImage(formData)
        this.multiple ? this.imgList.push(data) : this.imgList.splice(0, 1, data)
        this.$emit('success', file, this.imgList)
      } catch (error) {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="less" scoped>
.op-upload-image {
  /deep/ .ant-upload-list-item-image {
    object-fit: contain;
  }
  &__icon {
    font-size: 30px;
    color: #8a8f97;
  }
  &__text {
    color: #8a8f97;
  }
  &__image {
    position: relative;
    &__item {
      width: 102px;
      height: 102px;
      object-fit: contain;
    }
    &__close-icon {
      position: absolute;
      top: 3px;
      right: 3px;
    }
  }
  &__preview {
    padding-top: 20px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
/deep/ .ant-modal {
  width: 750px !important;
}
</style>
<style lang="less">
.op-upload-image {
  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0 !important;
  }
  .ant-upload {
    padding: 0 !important;
  }
}
</style>
