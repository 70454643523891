<template>
  <a
    :class="[
      'op-link',
      `op-link--${type}`,
      disabled && 'op-link--disabled',
      underline && !disabled && 'op-link--underline',
    ]"
    :href="disabled ? null : href ? href : 'javascript:void(0);'"
    v-bind="$attrs"
    @click="handleClick"
  >
    <span v-if="$slots.default" class="op-link--inner">
      <slot></slot>
    </span>
  </a>
</template>
<script>
export default {
  name: 'OpLink',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    underline: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
  },

  methods: {
    handleClick(event) {
      if (!this.disabled) {
        if (!this.href) {
          this.$emit('click', event)
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.op-link {
  cursor: pointer;
  &--primary {
    color: #00b876;
  }
  &--danger {
    color: #f65160;
  }
  &--default {
    color: #606266;
  }
  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &--underline {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
