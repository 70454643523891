<template>
  <div class="op-search-form-drawer">
    <div class="op-search-form-drawer__header">
      <div class="op-search-form-drawer__header-title">{{ $t('common.moreFilters') }}</div>

      <a-icon
        type="close"
        class="op-search-form-drawer__header-close"
        @click="close"
      />
    </div>
    <div class="op-search-form-drawer__body">
      <div
        v-for="(item, index) in rule"
        :key="index"
      >
        <div class="op-search-form-drawer__form-item">
          <div
            class="op-search-form-drawer__form-item__title"
            :class="!item.collapsed && 'op-search-form-drawer__form-item__title--active'"
            @click="toggleCollapsedStatus(item.field)"
          >
            <div>{{ item.title }}</div>
            <a-icon
              :type="item.collapsed ? 'down' : 'up'"
              :style="{ fontSize: '16px', color: '#969799', fontWeight: 'bold' }"
            />
          </div>
          <div
            v-if="!item.collapsed"
            class="op-search-form-drawer__form-item__content"
          >
            <component
              :is="COMPONENT_MAP[item.type]"
              :item="item"
              :value="value[item.field]"
              :in-search-form-drawer="true"
              @change="change"
            ></component>
          </div>
        </div>
      </div>
    </div>
    <div class="op-search-form-drawer__footer">
      <a-button @click="reset">{{ $t('common.resetText') }}</a-button>
      <a-button
        type="primary"
        @click="submit"
      >{{ $t('common.finishText') }}</a-button>
    </div>
  </div>
</template>

<script>
import FormItemComponents, { COMPONENT_MAP } from './form-item/index'
export default {
  name: 'OpSearchFormDrawer',
  components: {
    ...FormItemComponents,
  },
  props: {
    rule: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      COMPONENT_MAP,
    }
  },
  methods: {
    toggleCollapsedStatus(field) {
      const index = this.rule.findIndex(item => item.field === field)
      const item = this.rule[index]
      const newItem = { ...item, collapsed: !item.collapsed }
      this.rule.splice(index, 1, newItem)
    },
    change(key, value) {
      this.$set(this.value, key, value)
    },
    close() {
      this.$emit('close')
    },
    reset() {
      this.$emit('reset')
    },
    submit() {
      this.$emit('submit')
    },
  },
}
</script>

<style lang="less" scoped>
.op-search-form-drawer {
  position: fixed;
  top: 0;
  right: -5px;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  width: 375px;
  background: #fff;
  box-shadow: -2px 0 22px 0 rgb(185 191 201);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    padding: 0 24px;
    font-size: 18px;
    font-weight: 500;
    line-height: 64px;
    border-bottom: 1px solid #e8e8e8;
    &__close {
      cursor: pointer;
    }
  }

  &__body {
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
  }

  &__form-item {
    margin: 16px 0;
    &__title {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      padding: 0 24px 0 16px;
      margin-left: 4px;
      font-size: 16px;
      font-weight: 500;
      color: #323233;
      cursor: pointer;
      background: #fff;
      border-left: 4px solid transparent;
      &:hover {
        background: #f7f8fa;
        border-left: 4px solid #31af84;
      }
      &--active {
        border-left: 4px solid #31af84;
      }
    }
    &__content {
      padding: 8px 24px 0 24px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 24px;
    border-top: 1px solid #e8e8e8;
    /deep/ .ant-btn {
      margin-left: 8px;
    }
  }
}
</style>
