<template>
  <div
    v-if="tagList.length > 0"
    class="op-search-form-tag-list"
  >
    <a-tag
      v-for="tag in tagList"
      :key="tag.field"
      closable
      @close="change(tag.field, undefined)"
    >{{ tag.text }}</a-tag>
  </div>
</template>

<script>
import { removeEmptyField, formatDate } from '@/utils'

export default {
  name: 'OpSearchFormTagList',
  props: {
    rule: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    tagList() {
      const formData = removeEmptyField(this.value)
      return this.rule
        .filter(item => Object.keys(formData).includes(item.field))
        .map(item => {
          const value = formData[item.field]
          let text = `${item.title}：`
          if (['op-input', 'OpInput'].includes(item.type)) {
            text += value
          }
          if (['op-radio-select', 'OpRadioSelect'].includes(item.type)) {
            text += (item.options.find(option => option.value === value) || {}).label
          }
          if (['op-checkbox-select', 'OpCheckboxSelect'].includes(item.type)) {
            text += (value || [])
              .map(
                itemValue => (item.options.find(option => option.value === itemValue) || {}).label
              )
              .join('、')
          }
          if (['op-input-range', 'OpInputRange'].includes(item.type)) {
            const [startValue, endValue] = value || []
            if (typeof startValue === 'number') {
              text += `${$t('common.greaterThan')}${startValue}`
            }
            if (typeof startValue === 'number' && typeof endValue === 'number') {
              text += ' '
            }
            if (typeof endValue === 'number') {
              text += `${$t('common.lessThan')}${endValue}`
            }
          }
          if (['op-date-range', 'OpDateRange'].includes(item.type)) {
            const [startValue, endValue] = value || []
            if (startValue) {
              text += `${$t('common.greaterThan')}${formatDate(startValue.valueOf())}`
            }
            if (startValue && endValue) {
              text += ' '
            }
            if (endValue) {
              text += `${$t('common.lessThan')}${formatDate(endValue.valueOf())}`
            }
          }
          return {
            text,
            field: item.field,
          }
        })
    },
  },
  methods: {
    change(key, value) {
      this.$emit('change', key, value)
    },
  },
}
</script>

<style lang="less" scoped>
.op-search-form-tag-list {
  display: flex;
  flex-wrap: wrap;
  margin: 14px 0;
  /deep/ .ant-tag {
    margin-bottom: 8px;
    white-space: initial;
  }
}
</style>
