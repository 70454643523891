<template>
  <div class="op-activity-banner">
    <op-swiper
      :active-index="0"
      :component-id="businessType"
      :change-page-button="false"
    >
      <template v-for="item in bannerList">
        <swiper-slide
          :key="item.imgUrl"
          class="swiper-slide"
        >
          <img
            v-compress="{
              src: item.imgUrl,
            }"
            @click="goActivityPage(item)"
          />
        </swiper-slide>
      </template>
    </op-swiper>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { LANGUAGE_TYPE, NATION_TYPE, BANNER_LANG_TYPE } from '@/constants/common'
import { PortalAnalytics } from '@/utils'
export default {
  name: 'OpActivityBanner',
  props: {
    businessType: {
      type: String,
      default: null,
    },
    bannerStoreStateKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bannerLangMap: {
        [NATION_TYPE.NIGERIA]: {
          [LANGUAGE_TYPE.ENGLISH]: BANNER_LANG_TYPE.NIGERIA_EN,
          [LANGUAGE_TYPE.CHINESE]: BANNER_LANG_TYPE.NIGERIA_EN,
        },
        [NATION_TYPE.EGYPT]: {
          [LANGUAGE_TYPE.ARABIC]: BANNER_LANG_TYPE.EGYPT_AR,
          [LANGUAGE_TYPE.ENGLISH]: BANNER_LANG_TYPE.EGYPT_EN,
        },
      },
      isMobile: 0,
    }
  },

  computed: {
    ...mapState(['language', 'countryCode']),
    bannerList() {
      return (
        (this.bannerStoreState &&
          this.bannerStoreState[this.businessType] &&
          this.bannerStoreState[this.businessType]?.propagateInfoData[this.bannerLang]?.map(
            item => ({
              imgUrl: this.isMobile ? item.appImgUrl : item.pcImgUrl,
              linkUrl: this.isMobile ? item.appJumpUrl : item.pcJumpUrl,
              propagateId: item.propagateId,
            })
          )) ||
        []
      )
    },
    bannerLang() {
      return (
        this.bannerLangMap[this.countryCode] && this.bannerLangMap[this.countryCode][this.language]
      )
    },
    bannerStoreState() {
      return this.$store.state[this.bannerStoreStateKey]
    },
  },
  mounted() {
    this.setIsMobile()
    window.onresize = () => {
      this.setIsMobile()
    }
  },
  methods: {
    setIsMobile() {
      this.isMobile = document.body.clientWidth < 575
    },
    goActivityPage(record) {
      const { propagateId, linkUrl } = record
      PortalAnalytics.mc('spportal_banner_click', {
        bannerId: propagateId,
        propagateSpaceKey: this.bannerStoreStateKey,
        businessType: this.businessType,
        linkUrl: linkUrl,
      })
      window.open(linkUrl)
    },
  },
}
</script>
<style scoped lang="less">
.op-activity-banner {
  width: 100;
  cursor: pointer;
  img {
    width: 100%;
  }
}
</style>
