<script>
import PageHeader from 'ant-design-vue/es/page-header'
import { isPandovaApp } from '@/utils'

export default {
  name: 'OpPageCard',
  props: Object.assign({}, PageHeader.props, {
    maxWidth: {
      type: String,
      default: '100%',
    },
  }),
  data() {
    return {
      isPandovaApp: isPandovaApp(),
    }
  },
  render() {
    const props = {
      ...this.$props,
    }
    const on = {
      ...this.$listeners,
    }
    const pageHeader = (
      <div class={this.isPandovaApp ? 'op-header-back' : ''}>
        <a-page-header {...{ props, scopedSlots: { ...this.$scopedSlots } }} {...{ on }}>
          {Object.keys(this.$slots).map(name => (
            <template slot={name}>{this.$slots[name]}</template>
          ))}
        </a-page-header>
      </div>
    )

    return (
      <div class="op-page-card" style={{ maxWidth: props.maxWidth }}>
        {pageHeader}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.op-page-card {
  margin: 0 auto;
  /deep/ .ant-page-header {
    padding: 0;
    background-color: #f2f3f5;
    .ant-page-header-heading {
      margin-bottom: 12px;
    }
    .ant-page-header-content {
      padding-top: 0;
    }
  }
  .op-header-back {
    /deep/ .ant-page-header-back {
      display: none;
    }
  }
}
</style>
