import { formatNumber, formatMoney } from '@/utils'
import { JUDGE_TYPE } from '@/constants/common'
const formatShowPrice = record => {
  // -1代表价格没填，直接用原价展示
  return record.salePrice === -1 ? formatMoney(record.originalPrice) : formatMoney(record.salePrice)
}
const formatStockCount = (text, record) => {
  return record.trackStock === JUDGE_TYPE.YES
    ? formatNumber(text)
    : $t('product.productList.stockNoTrack')
}
export const getTableColumnsForSPU = () => [
  {
    dateIndex: 'customCheckbox',
    scopedSlots: { customRender: 'customCheckbox' },
    width: 20,
    align: 'center',
    customCell: () => ({
      style: {
        padding: '16px 0',
      },
    }),
  },
  {
    dataIndex: 'picUrl',
    title: $t('product.productEdit.images'),
    scopedSlots: { customRender: 'picUrl' },
    key: 'picUrl',
    minWidth: 80,
    customCell: () => ({
      style: {
        padding: '0 16px',
      },
    }),
  },
  {
    dataIndex: 'name',
    title: $t('order.orderDetail.productName'),
    key: 'name',
    minWidth: 100,
  },
  {
    dataIndex: 'stockCount',
    title: $t('product.productList.stockAmount'),
    customRender: (text, record) =>
      (record.skuInfoList || []).length > 0 ? '' : formatStockCount(text, record),
    minWidth: 100,
    align: 'right',
  },
  {
    dataIndex: 'salePrice',
    customRender: (text, record) =>
      (record.skuInfoList || []).length > 0 ? '' : formatShowPrice(record),
    title: $t('product.productEdit.salePrice'),
    minWidth: 100,
    align: 'right',
  },
]

export const getTableColumnsForSKU = () => [
  {
    dateIndex: 'customCheckbox',
    scopedSlots: { customRender: 'customCheckbox' },
    customCell: () => ({
      style: {
        padding: '16px 0',
      },
    }),
    align: 'right',
    width: 54,
  },
  {
    dataIndex: 'specValueNameList',
    title: $t('product.productEdit.sku'),
    customRender: specValueNameList => specValueNameList.join(' / '),
    minWidth: 100,
    customCell: () => ({
      style: {
        padding: '16px 5px',
      },
    }),
  },
  {
    dataIndex: 'stockCount',
    customRender: (text, record) => formatStockCount(text, record),
    key: 'stockCount',
    title: $t('product.productList.stockAmount'),
    minWidth: 100,
    align: 'right',
  },
  {
    dataIndex: 'salePrice',
    key: 'salePrice',
    customRender: (text, record) => formatShowPrice(record),
    title: $t('product.productEdit.salePrice'),
    minWidth: 100,
    align: 'right',
  },
]
