import { render, staticRenderFns } from "./op-radio-group.vue?vue&type=template&id=22ec97f0&scoped=true&"
import script from "./op-radio-group.vue?vue&type=script&lang=js&"
export * from "./op-radio-group.vue?vue&type=script&lang=js&"
import style0 from "./op-radio-group.vue?vue&type=style&index=0&id=22ec97f0&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22ec97f0",
  null
  
)

export default component.exports