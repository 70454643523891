<template>
  <div class="op-table-batch-operate">
    <span class="op-table-batch-operate__selected-text">
      <!-- {{ $t('discount.discountAdd.selectedItems') }}{{ selectedCount }} -->
      {{ selectText }}
    </span>
    <a-dropdown v-if="operationList.length" :trigger="['click']" placement="bottomLeft">
      <span class="op-table-batch-operate__selected-text op-table-batch-operate__operate-text">
        {{ $t('common.batchOperation') }}
        <a-icon type="caret-down" />
      </span>
      <a-menu slot="overlay">
        <a-menu-item
          v-for="(item, index) in operationList"
          :key="index"
          @click="item.callbackFunction"
        >
          {{ item.label }}
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <slot name="flex-justify-end"></slot>
  </div>
</template>

<script>
export default {
  name: 'OpTableBatchOperate',
  props: {
    selectedText: {
      type: String,
      default: ''
    },
    selectedTextReplace: {
      type: String,
      default: '$[X]',
    },
    selectedCount: {
      type: [Number, String],
      default: 0,
    },
    operationList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    selectText() {
      if (this.selectedText && this.selectedText.indexOf(this.selectedTextReplace) > -1) {
        return this.selectedText.replace(this.selectedTextReplace, this.selectedCount)
      }
      return `${this.selectedText || this.$t('discount.discountAdd.selectedItems')} ${this.selectedCount}`
    }
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.op-table-batch-operate {
  display: flex;
  align-items: center;
  &__selected-text {
    display: inline-block;
    height: 40px;
    padding: 0 12px;
    line-height: 40px;
    text-align: center;
    background-color: #f2f3f5;
    border: 1px solid #ebedf0;
  }
  &__operate-text {
    cursor: pointer;
  }
  /deep/.flex-justify-end {
    margin-left: auto;
    .ant-btn {
      margin-right: 20px;
      color: #ffffff;
      background-color: #8991a4;
      border: none;
      &:last-child {
        margin-right: 0;
      }
      &.ant-btn-primary {
        background-color: #00b876;
        border-color: #00b876;
        &[disabled='disabled'] {
          background-color: #8991a4;
          border: none;
        }
      }
    }
  }
}
</style>
