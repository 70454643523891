<template>
  <div class="op-search-form">
    <div class="op-search-form__inner">
      <a-row type="flex" justify="space-between" :gutter="[10, 10]">
        <a-col :flex="1">
          <a-row v-bind="{ gutter: [10, 10], ...row }">
            <a-col v-for="(item, index) in rule" :key="index" v-bind="item.col">
              <component
                :is="COMPONENT_MAP[item.type]"
                :item="item"
                :value="value[item.field]"
                @change="change"
                @changeVal="changeVal"
              ></component>
            </a-col>
          </a-row>
        </a-col>
        <a-col v-if="rule.length > 2 && showDrawer">
          <a-button @click="searchFormDrawerVisible = !searchFormDrawerVisible">
            {{ $t('common.moreFilters') }}
          </a-button>
          <transition name="search-form-drawer">
            <op-search-form-drawer
              v-if="searchFormDrawerVisible"
              :visible="false"
              :rule="rule"
              :value="value"
              @close="hideDrawer"
              @submit="submit"
              @reset="reset"
            ></op-search-form-drawer>
          </transition>
        </a-col>
      </a-row>
    </div>
    <op-search-form-tag-list :rule="rule" :value="value" @change="change"></op-search-form-tag-list>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import OpSearchFormTagList from './components/op-search-form-tag-list.vue'
import OpSearchFormDrawer from './components/op-search-form-drawer.vue'
import FormItemComponents, { COMPONENT_MAP } from './components/form-item/index'

export default {
  name: 'OpSearchForm',
  components: {
    OpSearchFormTagList,
    OpSearchFormDrawer,
    ...FormItemComponents,
  },
  props: {
    rule: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => ({}),
    },
    showDrawer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      COMPONENT_MAP,
      initialValue: {},
      searchFormDrawerVisible: false,
    }
  },
  created() {
    this.rule.forEach(item => {
      item.collapsed = true
      if (item.effect && item.effect.fetch) {
        item.effect.fetch(item)
      }
    })
    this.initialValue = cloneDeep(this.value)
  },
  methods: {
    change(key, value) {
      this.$set(this.value, key, value)
      this.$emit('submit')
    },
    changeVal(key, val) {
      this.$set(this.value, key, val)
    },
    hideDrawer() {
      this.searchFormDrawerVisible = false
    },
    submit() {
      this.hideDrawer()
      this.$emit('submit')
    },
    reset() {
      this.hideDrawer()
      Object.keys(this.value).forEach(key => {
        this.$set(this.value, key, cloneDeep(this.initialValue[key]))
      })
      this.$emit('submit')
    },
  },
}
</script>

<style lang="less" scoped>
.op-search-form {
  .search-form-drawer-enter-active,
  .search-form-drawer-leave-active {
    transition: all 0.5s;
  }
  .search-form-drawer-enter,
  .search-form-drawer-leave-to {
    transform: translateX(100%);
  }
  &__inner {
    margin: 0 0 14px;
  }
}
</style>
