<template>
  <div class="op-input-range">
    <a-dropdown
      v-if="!inSearchFormDrawer"
      :visible="visible"
      :trigger="['click']"
      :get-popup-container="getPopupContainer"
      @visibleChange="onVisibleChange"
    >
      <div
        slot="overlay"
        class="op-input-range__overlay"
      >
        <div class="op-input-range__overlay-range">
          <a-input
            v-model="min"
            class="op-input-range__input"
            type="number"
            allow-clear
            :placeholder="$t('common.minValue')"
            @change="changeStartValue"
          ></a-input>
          <span class="op-input-range__overlay-separator">-</span>
          <a-input
            v-model="max"
            class="op-input-range__input"
            type="number"
            allow-clear
            :placeholder="$t('common.maxValue')"
            @change="changeEndValue"
          ></a-input>
        </div>
        <a-button
          type="primary"
          class="op-input-range__button-ok"
          :disabled="clearButtonDisabled"
          @click="handleOk"
        >{{ $t('common.confirm') }}</a-button>
      </div>
      <a-button
        trigger="click"
        class="op-input-range__button"
      >
        {{ item.title }}
        <a-icon type="filter" />
      </a-button>
    </a-dropdown>
    <div v-if="inSearchFormDrawer">
      <a-space
        direction="vertical"
        class="op-input-range__space"
      >
        <a-input
          v-model="min"
          type="number"
          :placeholder="$t('common.minValue')"
          :allow-clear="true"
          @change="changeStartValue"
        ></a-input>
        <a-input
          v-model="max"
          type="number"
          :placeholder="$t('common.maxValue')"
          :allow-clear="true"
          @change="changeEndValue"
        ></a-input>
      </a-space>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { isEmptyValue } from '@/utils'
export default {
  name: 'OpInputRange',
  props: {
    inSearchFormDrawer: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    limitMax: {
      type: Number,
      default: 10000,
    },
    limitMin: {
      type: Number,
      default: -10000,
    },
  },
  data() {
    return {
      visible: false,
      max: '',
      min: '',
    }
  },
  computed: {
    eventName() {
      return this.inSearchFormDrawer ? 'change' : 'changeVal'
    },
    bothEmpty() {
      return isEmptyValue(this.min) && isEmptyValue(this.max)
    },
    valueRange() {
      const min = !isEmptyValue(this.min) ? Number(this.min) : ''
      const max = !isEmptyValue(this.max) ? Number(this.max) : ''
      return this.bothEmpty ? [] : [min, max]
    },
  },
  watch: {
    value() {
      this.getMinMax()
    },
  },
  created() {
    this.getMinMax()
  },
  methods: {
    getMinMax() {
      if ((this.value || []).length > 0) {
        this.min = this.value[0]
        this.max = this.value[1]
        return
      }
      this.min = ''
      this.max = ''
    },
    getPopupContainer(triggerNode) {
      return triggerNode.parentNode
    },
    onVisibleChange() {
      this.visible = !this.visible
    },
    changeStartValue: debounce(function (e) {
      let min = e.target.value

      if (typeof min === 'string' && min !== '') {
        min = Number(min)
      }

      if (min < this.limitMin) {
        min = this.limitMin
      }
      if (min > this.limitMax) {
        min = this.limitMax
      }
      const value = this.bothEmpty ? [] : [min, this.max]
      this.$emit(this.eventName, this.item.field, value)
    }, 800),
    changeEndValue: debounce(function (e) {
      let max = e.target.value

      if (typeof max === 'string' && max !== '') {
        max = Number(max)
      }
      if (max > this.limitMax) {
        max = this.limitMax
      }
      if (max < this.limitMin) {
        max = this.limitMin
      }
      const value = this.bothEmpty ? [] : [this.min, max]
      this.$emit(this.eventName, this.item.field, value)
    }, 800),

    handleOk() {
      this.$emit('change', this.item.field, this.valueRange)
      this.onVisibleChange()
    },
  },
}
</script>

<style lang="less" scoped>
.op-input-range {
  &__title {
    padding-left: 10px;
  }
  &__overlay {
    box-sizing: border-box;
    padding: 10px 10px 50px 10px;
    background: #fff;
    border: 1px solid #ebedf0;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__button-ok {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  &__overlay-range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__overlay-separator {
    width: 4%;
    color: #969799;
    text-align: center;
  }
  &__input {
    flex-grow: 0;
    width: 45%;
  }
  &__space {
    width: 100%;
  }
}
</style>
